$basecolor: rgb(248, 248, 248);
$contentWidth: 960px;
@mixin breakpoint($class) {
  @if $class == xs {
    @media (max-width: 767px) { @content; }
  }
 
  @else if $class == sm {
    @media (min-width: 768px) { @content; }
  }
 
  @else if $class == md {
    @media (min-width: 992px) { @content; }
  }

  @else if $class == desktop {
    @media (min-width: 992px) { @content; }
  }
 
  @else if $class == lg {
    @media (min-width: 1200px) { @content; }
  }
 
  @else {
    @warn "Breakpoint mixin supports: xs, sm, md, lg";
  }
}

html, body {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  padding: 0;
  margin: 0;
  background-color: $basecolor;
}


body {
  font-family: 'Poppins', sans-serif;
}

h2 {
  font-weight: 600;
  font-size: 1.3rem;
}

header {
  // padding: 1rem;
  // max-width: $contentWidth;
  // margin: auto;

  .header-graphic {
    min-height: 16rem;
    background-image: url(../img/twoflags.jpg);
    background-position: center;
    background-size: cover;
    position: relative;
    margin-bottom: 1rem;
    
    @include breakpoint(desktop) {
      margin-bottom: 3rem;
    }

    .inner {
      position: absolute;
      bottom: 0;
      width: 100%;
      text-align: center;
      height: 80%;

    }
  }

  h1.logo-header {
    background: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.411), 0 0 5px rgba(0, 0, 0, 0.226);
    position: relative;
  
    margin: auto;
    display: inline-block;
    display: flex;
    border-radius: 100%;
    text-align: center;
    margin-bottom: 2rem;
    padding: 2rem;
    height: 10rem;
    width: 10rem;
    justify-content: center;
    
    img {
      // display: flex;
      width: auto;
      width: 10rem;
      margin: 0;
      position: absolute;
      top: 50%;
      padding-left: 20px;
      
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);

    }
  }
}

.main-content {
  padding: 1rem;
  max-width: $contentWidth;
  margin: auto;
}

.intro-text {
  font-style: italic;
  font-weight: 300;
  font-size: 1.2rem;
  color: rgb(68, 68, 78);
  text-align: center;
  padding-bottom: 3rem;
  padding-top: 0;
  margin-top: 0;

  @include breakpoint(desktop) {
    text-align: left;
    font-weight: 400;
    padding-bottom: 2rem;
  }
}

.primary-intro {
  display: flex;
  flex-direction: column;
  
  @include breakpoint(desktop) {
    flex-direction: row;
    & > div:first-child {
      padding-right: 4rem;
    }
  }

}

@include breakpoint(desktop) {
  .col-1 {}
  .col-2 {
    min-width: 20rem;
  }
}

footer {
  margin-top: 2rem;
  background-color: rgb(231, 231, 231);
  padding: 2rem;

  li {
    a {
      padding-right: 0.25rem;
    }
  }

  .inner {
    padding: 1rem;
    max-width: $contentWidth;
    margin: auto;
    display: flex;
    flex-direction: column;

    @include breakpoint(desktop) {
      flex-direction: row;
    }
    section {
      @include breakpoint(desktop) {
        padding-left: 2rem;
        max-width: 50%;
        &:first-child {
          padding-right: 2rem;
          padding-left: 0;
        }
      }
    }
  }
}

.data-links {
  ul {
    list-style-type: none;
    padding: 0;

    li {
      border: 1px dotted rgb(200, 230, 245);
      background: rgb(234, 244, 247);
      margin-bottom: 1rem;
      padding: 0.5rem 1.2rem;
      border-radius: 0.25rem;
      box-shadow: 1px 1px 1px rgba(149, 181, 223, 0.425);
      font-size: 0.9rem;
    }
  }
}

.download-pdf-widget {
  a {
    color: #fff;
    text-decoration: none;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.685);
    	
    background-color: rgb(24,	27,	144);
    display: flex;
    padding: 0.25rem 1rem;
    border-radius: 0.5rem;
    position: relative;
    align-items: center;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.315);
    transition: background-color 500ms;

    &:hover {
      background-color: rgb(69, 92, 126);
    }

    .icon {
      font-size: 2rem;
      padding-right: 0.75rem;
      padding-top: 0.5rem;
    }
  }
}

.contact-area {
  margin-top: 3rem;
  font-weight: 500;
  background: #eee;
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;;
}

.download-link {
  display: flex;
  margin: auto;
  border-radius: 0.75rem;
  border: 1px solid gray;
  padding: 0.75rem 1rem;
  max-width: 25rem;
  justify-content: center;
  background-color: rgb(233, 233, 233);
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.445), 1px 1px 4px rgba(134, 134, 134, 0.438);
  transition: box-shadow 0.2s;

  &:hover {
    box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.445), 1px 1px 4px rgba(134, 134, 134, 0.838);
  }
  .icon {
    opacity: 0.8;
    color: red;
    padding-right: 0.75rem;
    font-size: 1.2em;
  }
}
