body, html {
  background-color: #f8f8f8;
  margin: 0;
  padding: 0;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

body {
  font-family: Poppins, sans-serif;
}

h2 {
  font-size: 1.3rem;
  font-weight: 600;
}

header .header-graphic {
  background-image: url("twoflags.b16d055f.jpg");
  background-position: 50%;
  background-size: cover;
  min-height: 16rem;
  margin-bottom: 1rem;
  position: relative;
}

@media (width >= 992px) {
  header .header-graphic {
    margin-bottom: 3rem;
  }
}

header .header-graphic .inner {
  text-align: center;
  width: 100%;
  height: 80%;
  position: absolute;
  bottom: 0;
}

header h1.logo-header {
  text-align: center;
  background: #fff;
  border-radius: 100%;
  justify-content: center;
  width: 10rem;
  height: 10rem;
  margin: auto auto 2rem;
  padding: 2rem;
  display: flex;
  position: relative;
  box-shadow: 0 0 10px #00000069, 0 0 5px #0000003a;
}

header h1.logo-header img {
  width: 10rem;
  margin: 0;
  padding-left: 20px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.main-content {
  max-width: 960px;
  margin: auto;
  padding: 1rem;
}

.intro-text {
  color: #44444e;
  text-align: center;
  margin-top: 0;
  padding-top: 0;
  padding-bottom: 3rem;
  font-size: 1.2rem;
  font-style: italic;
  font-weight: 300;
}

@media (width >= 992px) {
  .intro-text {
    text-align: left;
    padding-bottom: 2rem;
    font-weight: 400;
  }
}

.primary-intro {
  flex-direction: column;
  display: flex;
}

@media (width >= 992px) {
  .primary-intro {
    flex-direction: row;
  }

  .primary-intro > div:first-child {
    padding-right: 4rem;
  }

  .col-2 {
    min-width: 20rem;
  }
}

footer {
  background-color: #e7e7e7;
  margin-top: 2rem;
  padding: 2rem;
}

footer li a {
  padding-right: .25rem;
}

footer .inner {
  flex-direction: column;
  max-width: 960px;
  margin: auto;
  padding: 1rem;
  display: flex;
}

@media (width >= 992px) {
  footer .inner {
    flex-direction: row;
  }

  footer .inner section {
    max-width: 50%;
    padding-left: 2rem;
  }

  footer .inner section:first-child {
    padding-left: 0;
    padding-right: 2rem;
  }
}

.data-links ul {
  padding: 0;
  list-style-type: none;
}

.data-links ul li {
  background: #eaf4f7;
  border: 1px dotted #c8e6f5;
  border-radius: .25rem;
  margin-bottom: 1rem;
  padding: .5rem 1.2rem;
  font-size: .9rem;
  box-shadow: 1px 1px 1px #95b5df6c;
}

.download-pdf-widget a {
  color: #fff;
  text-shadow: 1px 1px #000000af;
  background-color: #181b90;
  border-radius: .5rem;
  align-items: center;
  padding: .25rem 1rem;
  text-decoration: none;
  transition: background-color .5s;
  display: flex;
  position: relative;
  box-shadow: 1px 1px 2px #00000050;
}

.download-pdf-widget a:hover {
  background-color: #455c7e;
}

.download-pdf-widget a .icon {
  padding-top: .5rem;
  padding-right: .75rem;
  font-size: 2rem;
}

.contact-area {
  background: #eee;
  border-radius: .5rem;
  margin-top: 3rem;
  padding: 1rem 1.5rem;
  font-weight: 500;
}

.download-link {
  background-color: #e9e9e9;
  border: 1px solid gray;
  border-radius: .75rem;
  justify-content: center;
  max-width: 25rem;
  margin: auto;
  padding: .75rem 1rem;
  transition: box-shadow .2s;
  display: flex;
  box-shadow: inset 0 0 2px #00000071, 1px 1px 4px #87878770;
}

.download-link:hover {
  box-shadow: inset 0 0 2px #00000071, 1px 1px 4px #878787d6;
}

.download-link .icon {
  color: red;
  opacity: .8;
  padding-right: .75rem;
  font-size: 1.2em;
}
/*# sourceMappingURL=index.c1f3fd86.css.map */
